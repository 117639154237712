import '../styles/globals.css';
import type { AppProps } from 'next/app';
import TagManager from "react-gtm-module";
import React, { useEffect } from "react";

const tagManagerArgs = {
	gtmId: process.env.NEXT_PUBLIC_GTM_TAG || "GTM-XXXXmm"
}

function MyApp({ Component, pageProps }: AppProps) {
	useEffect(() => {
		if (process.env.NEXT_PUBLIC_USE_GTM === "1") {
			TagManager.initialize(tagManagerArgs);
		}
	}, []);



	return (
		<Component {...pageProps} />
	);
}

export default MyApp;
